<form
  class="flex justify-start items-start flex-col lg:flex-row gap-x-28"
  (ngSubmit)="onSubmit()"
  [formGroup]="formValues"
>
  <div class="grow w-full lg:w-1/2" [ngClass]="{'px-4 lg:px-0': formType == 'STANDARD'}">
    <div title="Choose ticket" class="mb-12">
      <p
        class="font-black text-base text-zestBlack !mb-6"
        *ngIf="formType == 'TICKET'"
      >
        Choose Ticket
      </p>
      <div
        class="grid grid-cols-3 lg:grid-cols-2 items-center border-b w-full gap-x-3"
        *ngFor="let ticket of details?.ticketCategories"
      >
        <div class="py-4 col-span-2 lg:col-span-1">
          <p class="!mb-0 pb-2 font-semibold" [ngClass]="{'text-zestTertiary-500': ticket.quantityAvailable == 0}">
            {{ ticket?.name || "Regular" }}
          </p>
          <p class="!mb-0 font-bold" [ngClass]="{'text-[#98A2B3]': ticket.quantityAvailable == 0}">{{ ticket?.price | currency : "₦" }}</p>
          <p
            class="!mb-0 text-red-500 text-sm font-medium"
            *ngIf="message?.ticketName == ticket?.name"
          >
            {{ message.message }}
          </p>
        </div>
        <div class="text-right col-span-1">
          <select
            *ngIf="ticket.quantityAvailable > 0"
            type="text"
            (change)="handleTicketSelection($event, ticket)"
            class="bg-white outline-hidden w-20 text-left placeholder-zestPlaceholder rounded border border-solid border-[#c1c7d0] p-2 focus:border-zestGreen focus:ring-zestGreen"
          >
            <option
              [selected]="num == 0"
              *ngFor="let num of ticketLimit"
              [value]="num"
            >
              {{ num }}
            </option>
          </select>
          <div
            *ngIf="ticket.quantityAvailable == 0"
            class="w-20 rounded text-zestBlack bg-[#F9FAFB] border border-solid border-[#F9FAFB] p-2 place-self-end"
          >
            <p class="!mb-0 text-sm font-bold">Sold Out</p>
          </div>
        </div>
      </div>
    </div>
    <div title="Additional Information">
      <p
        class="font-black text-base text-zestBlack !mb-6"
        *ngIf="formType == 'TICKET'"
      >
        Additional Information
      </p>
      <div class="grid grid-cols-2 gap-x-4 lg:gap-x-6">
        <div>
          <label
            for="firstName"
            class="block py-2 font-normal leading-4 text-sm tracking-[0.01em] text-zestGrey"
          >
            First Name
          </label>
          <input
            class="bg-white w-full outline-hidden placeholder-zestPlaceholder rounded border border-solid border-[#c1c7d0] p-3 focus:border-zestGreen focus:ring-zestGreen"
            id="firstName"
            type="text"
            placeholder="First name"
            name="firstName"
            formControlName="firstName"
            required
          />
          <span
              class="text-red-500 pt-1"
              *ngIf="
                !formValues.get('firstName')?.valid &&
                formValues.get('firstName')?.touched
              "
              >*First name is required</span
            >
        </div>
        <div>
          <label
            for="lastName"
            class="block py-2 font-normal leading-4 text-sm tracking-[0.01em] text-zestGrey"
          >
            Last Name
          </label>
          <input
            class="bg-white w-full outline-hidden placeholder-zestPlaceholder rounded border border-solid border-[#c1c7d0] p-3 focus:border-zestGreen focus:ring-zestGreen"
            type="text"
            placeholder="Last name"
            name="lastName"
            id="lastName"
            formControlName="lastName"
            required
          />
          <span
            class="text-red-500 pt-1"
            *ngIf="
              !formValues.get('lastName')?.valid &&
              formValues.get('lastName')?.touched
            "
            >*Last name is required</span
          >
        </div>
      </div>
      <div class="mt-6">
        <label
          for="email"
          class="block py-2 font-normal leading-4 text-sm tracking-[0.01em] text-zestGrey"
        >
          Email Address
        </label>
        <input
          class="bg-white w-full outline-hidden placeholder-zestPlaceholder rounded border border-solid border-[#c1c7d0] p-3 focus:border-zestGreen focus:ring-zestGreen"
          type="email"
          placeholder="Email"
          name="email"
          id="email"
          formControlName="email"
          required
        />
        <span
          class="text-red-500 pt-1"
          *ngIf="
            !formValues.get('email')?.valid &&
            formValues.get('email')?.touched
          "
          >*Email is required</span
        >
        <span
          class="text-red-500 pt-1"
          *ngIf="
            formValues.get('email')?.errors?.email
          "
          >*Please use a valid email</span
        >
      </div>
      <div class="grow w-full mr-3" *ngIf="formType == 'STANDARD'">
        <label
          for=""
          class="block pt-6 pb-2 font-normal leading-4 text-sm tracking-[0.01em] text-zestGrey"
        >
          Amount
        </label>
        <div class="">
          <div class="relative rounded-md">
            <div class="absolute inset-y-0 left-0 flex items-center">
              <label for="metricUnit" class="sr-only">Weight</label>
              <select
                id="amount_sign"
                name="amount_sign"
                class="h-full rounded-md border-transparent bg-transparent py-0 pl-2 pr-8 focus:border-zestGreen focus:ring-zestGreen sm:text-sm"
              >
                <option>NGN</option>
              </select>
            </div>
            <input
              *ngIf="details.fixed; else isNotFixed"
              class="bg-white w-full h-[56px] font-semibold text-zestBlack disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 placeholder-zestPlaceholder outline-hidden rounded border border-solid border-[#c1c7d0] pl-[5.3rem] focus:border-zestGreen focus:ring-zestGreen"
              type="text"
              min="0"
              placeholder="0.00"
              disabled
              [value]="amountValue | number : '1.2-2'"
              name="amount"
            />
            <ng-template #isNotFixed>
              <input
                class="bg-white w-full h-[56px] disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 placeholder-zestPlaceholder outline-hidden rounded border border-solid border-[#c1c7d0] pl-[5.3rem] focus:border-zestGreen focus:ring-zestGreen"
                type="number"
                min="0"
                placeholder="0.00"
                name="amount"
                step="100"
                formControlName="amount"
              />
            </ng-template>
          </div>
        </div>
        <span
          class="text-red-500 pt-1"
          *ngIf="
            !formValues.get('amount')?.valid &&
            formValues.get('amount')?.touched
          "
          >*Amount is required</span
        >
      </div>
      <div formArrayName="response" *ngIf="details?.fields?.length > 0">
        <div
          [formGroupName]="i"
          class="grow w-full mr-3"
          *ngFor="let control of response.controls; let i = index"
        >
          <label
            for="fields"
            class="block pb-2 pt-6 font-normal leading-4 text-sm tracking-[0.01em] text-zestGrey"
          >
            {{ details?.fields[i] }}
          </label>
          <input
            class="bg-white w-full h-[56px] outline-hidden placeholder-zestPlaceholder rounded border-[1px] border-solid border-[#c1c7d0] p-4 focus:border-zestGreen focus:ring-zestGreen"
            type="text"
            id="fields"
            [name]="details?.fields[i]"
            [formControlName]="details?.fields[i]"
            required
          />
          <span
            class="text-red-500 pt-1"
            *ngIf="
              (!formValues.controls.response
                .get(i.toString())
                ?.get(details?.fields[i])?.valid &&
                formValues.controls.response
                  .get(i.toString())
                  ?.get(details?.fields[i])?.touched) ||
              formValues.controls.response
                .get(i.toString())
                ?.get(details?.fields[i])?.value === ''
            "
            >*This field is required</span
          >
        </div>
      </div>
      <button
        type="submit"
        *ngIf="formType == 'STANDARD'"
        [disabled]="formValues.invalid"
        class="text-white bg-zestGreen p-3 w-full rounded-lg mt-8 mb-3 disabled:cursor-not-allowed flex justify-center items-center"
      >
        <svg class="mr-2" *ngIf="pageSubmit || postingResponse" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
          <path fill="currentColor" d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z" opacity="0.25" />
          <path fill="currentColor" d="M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z">
            <animateTransform attributeName="transform" dur="0.75s" repeatCount="indefinite" type="rotate" values="0 12 12;360 12 12" />
          </path>
        </svg>
        <p class="!mb-0">
          <span *ngIf="!pageSubmit && !postingResponse">Make Payment</span>
          <span *ngIf="pageSubmit || postingResponse">Processing</span>
        </p>
      </button>
    </div>
  </div>
  <div
    class="border p-6 rounded-lg grow w-full lg:w-1/2 mt-10 lg:mt-0"
    *ngIf="formType == 'TICKET'"
  >
    <p class="font-black text-base text-zestBlack !mb-6">Summary</p>
    <div class="bg-[#F2F4F7] rounded-lg" formArrayName="ticketPurchaseDetails">
      <div
        *ngIf="purchasedTickets.controls.length > 0"
        class="border-t mx-4 py-2"
      >
        <div
          *ngFor="
            let ticketItem of purchasedTickets.controls;
            let i = index;
            let first = first
          "
          [formGroupName]="i"
        >
          <div
            class="border-t text-zestTertiary-500"
            [ngClass]="{ 'border-t-0': first }"
          >
            <div class="grid grid-cols-2 py-4">
              <p class="!mb-0">
                {{ ticketItem.get("quantity")?.value }} x
                {{ ticketItem.get("name")?.value }}
              </p>
              <p class="!mb-0 text-right">
                {{ ticketItem.get("subtotal")?.value | currency : "₦" }}
              </p>
            </div>
          </div>
        </div>
        <div class="grid grid-cols-2 py-4 border-t text-zestBlack font-bold">
          <p class="!mb-0">Total Amount</p>
          <p class="!mb-0 text-right">{{ overallSubTotal | currency : "₦" }}</p>
        </div>
      </div>
      <p
        *ngIf="purchasedTickets.controls.length < 1"
        class="font-medium text-sm text-center py-4 text-zestTertiary-500 !mb-0"
      >
        Please choose a ticket type to continue
      </p>
    </div>
    <button
      type="submit"
      [disabled]="formValues.invalid"
      *ngIf="purchasedTickets.controls.length > 0"
      class="text-white bg-zestGreen p-3 w-full rounded-lg mt-6 disabled:cursor-not-allowed flex justify-center items-center"
    >
      <svg class="mr-2" *ngIf="pageSubmit || postingResponse" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
        <path fill="currentColor" d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z" opacity="0.25" />
        <path fill="currentColor" d="M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z">
          <animateTransform attributeName="transform" dur="0.75s" repeatCount="indefinite" type="rotate" values="0 12 12;360 12 12" />
        </path>
      </svg>
      <p class="!mb-0">
        <span *ngIf="!pageSubmit && !postingResponse">Make Payment</span>
        <span *ngIf="pageSubmit || postingResponse">Processing</span>
      </p>
    </button>
  </div>
</form>