<section class="h-screen overflow-visible">
  <div class="w-full md:w-6/12 lg:w-4/12 my-3 mx-auto font-mont">
    <div class="flex flex-col items-center justify-center w-full">
      <div class="bg-zestBg rounded-lg p-4 flex justify-start items-start w-full">
        <div class="h-20 mr-4 overflow-hidden">
          <img *ngIf="details?.imageData?.image_source; else default" [src]="details?.imageData?.image_source"
            alt="page_logo" class="rounded-lg h-full object-center object-contain" />
          <ng-template #default>
            <img src="../../../../../assets/img/paymentpagedefault.png" alt="page_logo"
              class="rounded-lg w-full object-center" />
          </ng-template>
        </div>
        <div>
          <p class="font-semibold text-zestBlack text-xl">
            {{ details?.subPageName }}
          </p>
          <p class="text-zestBlack">
            by
            <span class="text-zestBlack font-medium">{{
              businessName
              }}</span>
          </p>
        </div>
      </div>

      <div class="">
        <app-payment-information [details]="details"></app-payment-information>
      </div>
    </div>
  </div>
  <div class="text-center bg-zestBg py-12">
    <footer class="text-zestBlack font-medium text-base pb-4 font-mont px-4 lg:px-0">
      This payment will be received by {{ businessName }}
    </footer>
    <div class="hidden lg:flex mt-10 items-center font-mont justify-center">
      <img class="pr-2 w-8" src="../../../assets/svg/Lock.svg" alt="email" />
      <small class="block text-zestLabel font-semibold text-sm">Secured By
        <a href="{{ setupStoreLink }}" target="_blank" rel="noopener" class="text-zestGreen">Zest</a></small>
    </div>
  </div>
</section>