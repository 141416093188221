import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SnackBarService } from '../shared/services/snackbar.service';
import { PaymentService } from '../shared/services/payment.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
declare var ZestPayPop: any;

@Component({
  selector: 'app-invoice-payment',
  templateUrl: './invoice-payment.component.html',
  styleUrls: ['./invoice-payment.component.scss'],
})
export class InvoicePaymentComponent implements OnInit {
  setupStoreLink!: string;
  form: FormGroup;
  pageCreation: boolean = false;
  showInvalid: boolean = false;
  publicKey!: string;
  token!: string;
  merchantId!: string;
  invoiceNumber!: string;
  invoiceDetails: any;

  queryParams: any;
  queryString: any;
  message!: boolean;
  success: boolean = false;

  constructor(
    public snackBar: SnackBarService,
    private payment: PaymentService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router
  ) {
    this.setupStoreLink = environment.SETUP_ZEST_PROFILE_URL;
    this.form = this.fb.group({
      invoiceNumber: new FormControl(null, [Validators.required]),
      merchantId: new FormControl(this.merchantId, [Validators.required]),
      token: new FormControl(this.token, [Validators.required]),
      tranRef: new FormControl(''),
    });
  }

  createQuery(obj: any) {
    return Object.keys(obj)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`
      )
      .join('&');
  }

  getPublicKey() {
    this.payment.getKey(this.merchantId).subscribe({
      next: (res: any) => {
        this.publicKey = res;
      },
      error: (err) => {},
    });
  }

  makePayment() {
    console.log('working');
    let trxRef: string = '';
    let handler = ZestPayPop.setup({
      key: this.publicKey, // Replace with your public key
      email: this.invoiceDetails.customerEmail,
      amount: this.invoiceDetails.total * 100,
      metadata: {
        source: 'Invoice',
      },
      onClose: (response: any) => {},
      callback: (response: any) => {
        trxRef = response.reference;
        console.log(trxRef);
        this.updateInvoicePayment(trxRef);
      },
    });
    handler.openIframe();
  }

  updateInvoicePayment(trx: string) {
    this.form.get('tranRef')?.setValue(trx);
    this.form.get('invoiceNumber')?.setValue(this.invoiceNumber);
    this.form.get('token')?.setValue(this.token);
    this.form.get('merchantId')?.setValue(this.merchantId);
    this.pageCreation = true;
    this.payment.updateInvoiceTrx(this.form.value).subscribe({
      next: (res) => {
        this.pageCreation = false;
        this.success = true;
        this.router.navigate(['invoice', 'pay', 'success']);
        console.log(res);
      },
      error: (err) => {
        this.success = false;
        this.snackBar.openSnackBar(`Unable to complete`, 'error-snackbar');
        console.log(err);
      },
    });
  }

  getInvoiceStatus() {
    let request = this.createQuery(this.queryParams);
    this.payment.invoiceStatus(request).subscribe({
      next: (res: any) => {
        this.invoiceDetails = res.data;
        this.merchantId = this.invoiceDetails.merchantId;
        this.token = this.invoiceDetails.token;
        this.showInvalid = false;
        this.pageCreation = false;
        this.getPublicKey();
      },
      error: (err) => {
        this.pageCreation = false;
        this.showInvalid = true;
        if (err.error.message.includes('this resource cannot be found')) {
          this.message = true;
        } else {
          this.message = false;
        }
      },
    });
  }

  ngOnInit(): void {
    this.pageCreation = true;

    this.route.queryParams.subscribe((params) => {
      this.queryParams = params;
      this.invoiceNumber = params.invoiceNumber;
    });

    this.getInvoiceStatus();
  }
}
