<app-loading-spinner *ngIf="pageCreation"></app-loading-spinner>
<section *ngIf="pageFound" class="font-mont">
  <div class=""></div>
  <p class="pt-20 pb-6 font-medium text-lg lg:text-[28px] lg:leading-10 text-center">
    Select what you want to pay for
  </p>
  <div class="mx-10 xl:mx-[300px]">
    <div class="grid gap-6 sm:grid-cols-1 lg:auto-cols-auto max-h-96 overflow-y-auto overflow-x-hidden" [ngClass]="{
        'md:grid-cols-2': paymentPages.length >= 2,
        'lg:grid-cols-4': paymentPages.length >= 4
      }">
      <div *ngFor="let paymentPage of paymentPages | paginate
      : {
          itemsPerPage: 8,
          currentPage: currentPage
        };" (click)="selectPage(paymentPage.subpageCode)" [ngClass]="{
          'border-zestGreen bg-zestGreenLight': selectedPage === paymentPage.subpageCode, 'bg-zestNeutral': selectedPage !== paymentPage.subpageCode
        }"
        class="border cursor-pointer hover:border-zestGreen rounded-lg bg-zestNeutral flex flex-col justify-center items-center px-5 py-10">
        <div class="">
          <div class="w-full overflow-hidden">
            <img *ngIf="paymentPage?.imageData?.image_source; else default"
              src="{{ paymentPage?.imageData?.image_source }}" alt="page_link_logo"
              class="rounded-full w-14 h-14 mx-auto object-center object-cover" />
            <ng-template #default>
              <img src="../../../../../assets/img/paymentpagedefault.png" alt="page_link_logo"
                class="rounded-full w-14 h-14 mx-auto object-center" />
            </ng-template>
          </div>
          <p class="my-10 text-center font-medium text-lg pt-10">
            {{ paymentPage?.subPageName }}
          </p>
        </div>
      </div>
    </div>
    <div class="grid gap-2 sm:grid-cols-2 lg:gap-10 place-items-center">
      <p class="pb-2 pt-2 md:pb-0 col-span-1 place-self-center md:place-self-start">
        Showing page {{ currentPage }} of {{ lastpage }}
      </p>
      <pagination-controls class="custom-pagination items-center lg:pl-0 mt-2 place-self-center md:place-self-end" previousLabel="Prev"
        (pageChange)="currentPage = $event"></pagination-controls>
    </div>
    <div class="flex justify-center items-center">
      <button [disabled]="disable" (click)="showPaypage()"
        class="rounded-lg text-white bg-zestGreen my-14 w-full md:w-1/3 py-3 px-10 cursor-pointer disabled:bg-zestPlaceholder">
        Proceed
      </button>
    </div>
  </div>
  <div class="hidden lg:flex items-center justify-center">
    <img class="pr-2 w-8" src="../../../assets/svg/Lock.svg" alt="email" />
    <small class="block text-zestLabel font-semibold text-sm">Secured By
      <a href="{{ setupStoreLink }}" target="_blank" rel="noopener" class="text-zestGreen">Zest</a></small>
  </div>
</section>
<section class="flex flex-col justify-center items-center font-mont overflow-y-visible" *ngIf="!pageFound && !pageCreation">
  <section class="px-2 pt-10 lg:pt-20  lg:px-0 font-mont flex flex-col justify-center items-center">
    <div class="flex flex-col justify-center items-center my-auto">
      <p class="pt-10 text-2xl font-semibold tracking-tighter-[0.48px] text-center">Payment page not found</p>
      <p class="pt-1 text-center">Please check the link and try again<br class="hidden lg:block" /></p>
    </div>
  </section>
</section>