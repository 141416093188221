import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { PaymentService } from '../shared/services/payment.service';
import { SnackBarService } from './../shared/services/snackbar.service';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
})
export class LandingPageComponent implements OnInit {
  constructor(
    public dialog: MatDialog,
    private snackBar: SnackBarService,
    private router: Router,
    private route: ActivatedRoute,
    private payment: PaymentService
  ) {
    this.setupStoreLink = environment.SETUP_ZEST_PROFILE_URL;
  }

  paymentPages: any[] = [];
  pageFound: boolean = false;
  pageCode: string = JSON.parse(localStorage.getItem('pageCode')!);
  count!: number;
  pageData: any;
  pageCreation: boolean = false;
  disable: boolean = true;
  selectedPage: string = '';
  setupStoreLink!: string;
  code: any;
  showBorder: any[] = [];
  currentPage: number = 1;
  lastpage!: number;

  subPageData!: any[];

  selectPage(spc: string) {
    this.disable = false;
    this.selectedPage = spc;

    this.showBorder.forEach((show, i) => {
      if (show.code === spc) {
        show.visible = !show.visible;
      }
    });
  }

  showPaypage() {
    this.router.navigate(['/pay', this.selectedPage]);
  }

  getAllpages(code: string) {
    this.pageCreation = true;
    this.payment.allPages(code).subscribe({
      next: (res: any) => {
        this.pageCreation = false;
        this.pageFound = true;
        this.pageData = res;
        this.paymentPages = this.pageData.data.content;
        let page = Math.floor(this.paymentPages.length % 8);
        this.lastpage =
          page === 0
            ? Math.floor(this.paymentPages.length / 8)
            : Math.floor(this.paymentPages.length / 8 + 1);

        for (const payment of this.paymentPages) {
          this.showBorder.push({
            code: payment.subpageCode,
            visible: false,
          });
        }
      },
      error: (err) => {
        // this.snackBar.openSnackBar(`Unable to complete`, 'error-snackbar');
        this.pageCreation = false;
        this.pageFound = false;
      },
    });
  }

  ngOnInit(): void {
    this.code = this.route.snapshot.params['id'];
    if (!this.code) {
      window.location.href = this.setupStoreLink;
      return;
    }

    sessionStorage.setItem('code', JSON.stringify(this.code));

    this.getAllpages(this.code);
    window.scrollTo(0, 0);
  }
}
